export const userServiceUrl = 'https://api.portal.forumviasanitas.org/wks-user-service';
export const trainingServiceUrl = 'https://api.portal.forumviasanitas.org/wks-training-service';
export const logServiceUrl = 'https://api.portal.forumviasanitas.org/wks-log-service';
export const messageServiceUrl = 'https://api.portal.forumviasanitas.org/wks-message-service';
export const dmsUrl = 'https://api.portal.forumviasanitas.org/wks-dms-service';
export const fileProcessingServiceUrl = 'wss://api.portal.forumviasanitas.org/wks-file-processing-service/ws';
export const productRegistrationServiceUrl = 'https://api.portal.forumviasanitas.org/wks-product-registration-service';
export const keycloakUrl = 'https://keycloak.portal.forumviasanitas.org';
export const realm = 'wikisanitas-prod';
export const clientId = 'wikisanitas-prod-client';
export const bucket = 'traning-service-dev';
export const version = '1.0';
export const dmsApiVersion = 'v1.0';
export const userApiVersion = 'v1.0';
export const trainingApiVersion = 'v1.0';
export const logApiVersion = 'v1.0';
export const messageApiVersion = 'v1.0';
export const forumServiceShopLink = 'https://service.forumviasanitas.org';
